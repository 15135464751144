import { Component, EventEmitter, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import "signalr";
import { GlobalService } from 'src/app/helpers/services/global.service';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/authentication/_services/auth.service';
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: [],
})


export class MainLayoutComponent implements OnInit {
  private proxy: any;
  private hubConnection: any;
  private connectionEstablished: EventEmitter<Boolean> ;
  private Id: string;
  private baseUrl: string = environment.baseSignalRUrl;
  constructor( public globalService: GlobalService,    private authenticationService: AuthService) {

    this.connectionEstablished = new EventEmitter<Boolean> ();
    this.hubConnection = $.hubConnection(this.baseUrl + 'signalr/hubs', { useDefaultPath: false });
    this.proxy = this.hubConnection.createHubProxy("signalRNotificationHub");

    this.Id = "3PL_" + JSON.parse(localStorage.getItem('companyId'));
    this.startConnection();
    this.registerOnServerEvents();
  }

  private startConnection(): void {
    console.log(this.Id);
    this.hubConnection.start({ withCredentials: false }).done((data: any) => {


    // console.log('hubConnection data', data);
    // console.log('this.Id', this.Id);

    this.proxy.invoke('ChainConnect', this.Id);



    console.log('Now connected ' + data.transport.name + ', connection ID= ' + data.id);
    console.log(this.Id);
    this.connectionEstablished.emit(true);

    }).fail((error: any) => {
      console.log('Could not connect ' + error);
      this.connectionEstablished.emit(false);
    });

  }


  private registerOnServerEvents(): void {
    this.proxy.on('SendNotificationToChain', (data: any) => {
      // console.log('received in SignalRService: ' + JSON.stringify(data));
      // console.log(data.Notification);
      // alert("trigger");
      console.log(data);
      // this.playAssignmentAudio();

      Swal.fire({
        title: "Your company is terminated. Please contact TMDone",
        text: "You will be redirect to Login",
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        allowOutsideClick:false,
        confirmButtonText: 'Continue'
      }).then((result) => {
        if (result.isConfirmed) {
          // Swal.fire(
          //   'Deleted!',
          //   'Your file has been deleted.',
          //   'success'
          // )
    this.authenticationService.logout();
    location.reload();      
        }
      })
      // this.globalService.showErrorMsgAlert(data.Notification)

      // this.notifyNotification(data.Notification);


    });
  }


  notifyNotification(notification: string){
    if(notification == 'ASSIGNMENT'){
      // this.dashboardSharedService.setAssignmentsOnChange();
      // this.showInfo('New Assignment !')
      this.playAssignmentAudio();
    }
    else if(notification == 'STORE_STATUS_CHANGE'){
      // this.dashboardSharedService.setNotificationsOnChange();
      this.playAssignmentAudio();
    }
  }


  playAssignmentAudio(){
    let audio = new Audio();
    audio.src = "../../../../../assets/audio/assignment.mp3";
    audio.load();
    var promise = audio.play();
    //console.log(promise)
    if (promise !== undefined) {
      promise.then(_ => {
        // Autoplay started!
      }).catch(error => {
        // Autoplay was prevented.
        // Show a "Play" button so that user can start playback.
        this.playAssignmentAudio();
      });
    }
  }



  ngOnInit(): void {}
}
