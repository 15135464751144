import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalService } from 'src/app/helpers/services/global.service';
import { ChangePasswordRequestBody } from '../../models/change-password.model';
import { ChangePasswordService } from '../../services/change-password.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  changePasswordForm: FormGroup;
  changePasswordRequestBody: ChangePasswordRequestBody;

  hidePassword = true;
  hideNewPassword = true;
  hideConfirmPassword = true;

  constructor(
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private changePasswordService: ChangePasswordService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.createPasswordResetForm();
  }

  createPasswordResetForm(){
    this.changePasswordForm = this.fb.group({
      OldPassword:      ['', Validators.required] ,
      Password:         ['', Validators.required],
      ConfirmPassword:  ['', Validators.required],
    })
  }


  resetPassword(formData: any){
    this.setRequestBody(formData);
    this.resetPasswordInDb();
  }


  setRequestBody(formData: any){
    this.changePasswordRequestBody = {
      OldPassword:      formData.OldPassword,
      Password:         formData.Password,
      ConfirmPassword:  formData.ConfirmPassword,
    }
  }


  resetPasswordInDb(){
    this.changePasswordService.resetPassword(this.changePasswordRequestBody)
      .pipe(takeUntil(this.destroy))
      .subscribe((res) => {
        if(res == 'SUCCESS'){
          this.changesOnSuccess();
        }
      })
  }


  changesOnSuccess(){
    this.globalService.showSuccessMsgAlert('Password changed Successfully !');
    this.close();
  }


  close(){
    this.dialogRef.close();
  }

}
