// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  baseUrl: 'https://driverecompanyapi.uat.v3.tmd1.org/',
  //baseUrl: 'https://api.tmdone.com:8443/',
  baseSignalRUrl: 'signalrapi.uat.v3.tmd1.org/',

  firebaseConfig : {
    apiKey: "AIzaSyDJAZJUFFlEVhhEgFG6BoXckPdowT7E2l4",
    authDomain: "tmdone-592c5.firebaseapp.com",
    databaseURL: "https://tmdone-592c5.firebaseio.com",
    projectId: "tmdone-592c5",
    storageBucket: "tmdone-592c5.appspot.com",
    messagingSenderId: "621826848728",
    appId: "1:621826848728:web:22dd2c1f3be11fb970a4ad",
    measurementId: "G-C00QVN3RQM"

  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
