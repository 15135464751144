import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AuthResponse} from '../_models/signin.model';
import { BaseDataService } from 'src/app/core/services/base-data.service';
import { CompanyList } from '../_models/companyList.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl: string = `${environment.baseUrl}`;
  private userApi = 'api/user'

  private currentUserSubject: BehaviorSubject<AuthResponse>;
  public currentUser: Observable<AuthResponse>;

  grant_type: string = 'password';
  Authorization = '';
  app = '';
  t = '';
  v = ''

  constructor(
    private http: HttpClient,
    private router: Router,
    private baseDataService: BaseDataService
    ) {
      this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
      this.currentUser = this.currentUserSubject.asObservable();
    }


  public get currentUserValue(): AuthResponse {
    return this.currentUserSubject.value;
  }


  login(username: any, password: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = new URLSearchParams();

    body.set('grant_type', this.grant_type );
    body.set('Authorization', this.Authorization);
    body.set('app', this.app);
    body.set('t', this.t);
    body.set('v', this.v);
    body.set('username', username);
    body.set('password', password);

    return this.http.post<AuthResponse>(this.baseUrl+'token',  body.toString(), {
        headers: myheader}).pipe(map((user: AuthResponse) => {
          this.storeDetails(user)
          this.currentUserSubject.next(user);
          return user;
    }))
  }


  storeDetails(user: any){
    localStorage.setItem('currentUser', JSON.stringify(user));
    localStorage.setItem('token', JSON.stringify(user.access_token));
    localStorage.setItem('username', JSON.stringify(user.userName));
    localStorage.setItem('userId', JSON.stringify(user.userId));
    localStorage.setItem('email', JSON.stringify(user.email));
    localStorage.setItem('isChainUser', JSON.stringify(user.isChainUser));
    localStorage.setItem('companyId', JSON.stringify(null));
  }


  logout(){
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/']);
  }


  getCompanyList(userId: string): Observable<CompanyList[]>{
    let queryString = `userId=${userId}`
    return this.baseDataService.makeCompanyGetCall(`${this.userApi}${'/listCompanies'}?${queryString}`)
  }

}
