import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  companyId: string;
  companyName: string;
  sortCode: string;
  phone: string;

  constructor(
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) { }


  getCompanyId(){
    this.companyId = JSON.parse(localStorage.getItem('companyId'));
    return this.companyId
  }


  getCompanyName(){
    this.companyName = JSON.parse(localStorage.getItem('companyName'));
    return this.companyName;
  }


  getSortCode(){
    this.sortCode = JSON.parse(localStorage.getItem('SortCode'));
    return this.sortCode;
  }


  getCompanyPhone(){
    this.phone = JSON.parse(localStorage.getItem('Phone'));
    return this.phone;
  }


  setDate(start:any, end:any){
    let startDate = this.datePipe.transform(start, 'yyyy/MM/dd');
    let endDate;

    if(end == null){
      endDate = this.datePipe.transform(start, 'yyyy/MM/dd')
    }else{
      endDate = this.datePipe.transform(end, 'yyyy/MM/dd');
    }

    return startDate + '-' + endDate;
  }


  showSuccessMsgAlert(msg:string) {
    this.toastr.success(msg, 'Success!', {
      timeOut: 3000
    });

  }

  showErrorMsgAlert(msg:string) {
    this.toastr.error(msg, 'Error!', {
      timeOut: 3000
    });

  }


  setDateFilter(start: any, end: any) {
    if (end == null || end == '') {
      end = start;
    }

    let startDate = this.datePipe.transform(start, 'yyyy/MM/dd');
    let endDate = this.datePipe.transform(end, 'yyyy/MM/dd');
    let requestDateRange = startDate + '-' + endDate;
    return requestDateRange;
  }


  setDateToFormat(start: any){
    let date = this.datePipe.transform(start, 'yyyy/MM/dd');
    return date;
  }


  getFixedDecimalPlaces(number: number, place:number){
    let num: any = number?.toFixed(place);
    return num;
  }

}
