import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
  @Input() url: string;
  isLoaded: boolean = false;
  safeUrl: SafeResourceUrl;
  constructor(public sanitizer:DomSanitizer) { }

  ngOnInit(): void {
    // alert(this.url)
    // this.isLoad();
   this.safeUrl= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  isLoad() {
    setTimeout(() => {
      this.isLoaded = true;
      alert(this.isLoaded);
    }, 2000);
  }
}
