import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

  // Common Module

  {
    path: '',
    title: 'Main',
    moduleName: '',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  {
    path: 'home/dashboard',
    title: 'Dashboard',
    moduleName: 'dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  {
    path: 'home/driver',
    title: 'Drivers',
    moduleName: 'driver',
    iconType: 'material-icons-two-tone',
    icon: 'directions_car',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  {
    path: 'home/order',
    title: 'Orders',
    moduleName: 'order',
    iconType: 'material-icons-two-tone',
    icon: 'shopping_cart',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  {
    path: '',
    title: 'Reports',
    moduleName: 'Reports',
    iconType: 'material-icons-two-tone',
    icon: 'book',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [
      {
        path: 'home/reports/driver-status',
        title: 'Driver Status Report',
        moduleName: 'reports',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: 'home/reports/finance',
        title: 'Finance Summary',
        moduleName: 'reports',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ],
  },

  {
    path: '',
    title: 'Settlement',
    moduleName: 'Settlement',
    iconType: 'material-icons-two-tone',
    icon: 'paid',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [
      {
        path: 'home/Settlement/driver-payment',
        title: 'Company Settlement',
        moduleName: 'Settlement',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },

      {
        path: 'home/Settlement/pending-receipts',
        title: 'Pending Receipts',
        moduleName: 'Settlement',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ],
  },

  {
    path: 'home/bird-eye-view',
    title: 'Bird Eye View',
    moduleName: 'bird-eye-view',
    iconType: 'material-icons-two-tone',
    icon: 'add_location',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

];
