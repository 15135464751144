import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorInterceptorService } from '../_services/error-interceptor.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
      private authenticationService: AuthService,
      private errorInterceptorService: ErrorInterceptorService,
      
    ) { }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
          .pipe(catchError(err => {
            this.errorInterceptorService.handleError(err)
            // if (err.status === 401) {
            //     // auto logout if 401 response returned from api
            //     this.authenticationService.logout();
            //     location.reload();
            // }
// console.log(err);



            const error = err.error.message || err.statusText || err.error ;
            return throwError(error);
        }))
    }
}
