import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-total-results',
  templateUrl: './total-results.component.html',
  styleUrls: ['./total-results.component.scss']
})
export class TotalResultsComponent implements OnInit {
  @Input() count: number;
  resultsCount: number = 0;

  constructor() { }

  ngOnInit(): void {
  }
  
  ngOnChanges(): void {
    this.resultsCount = this.count;
  }

}
