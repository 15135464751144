import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from 'src/app/core/services/base-data.service';
import { ChangePasswordRequestBody } from '../models/change-password.model';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {
  private apiChangePasswordUrl = '/api/profile/changePassword';

  constructor(
    private baseDataService: BaseDataService
  ) { }


  resetPassword(changePasswordRequestBody: ChangePasswordRequestBody):Observable<any>{
    return this.baseDataService.makePostCall(`${this.apiChangePasswordUrl}`, changePasswordRequestBody)
  }
}
