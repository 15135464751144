import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService {

  constructor(
    private authenticationService: AuthService,
    private toastr: ToastrService
  ) { }


  handleError(error: HttpErrorResponse){
    let status = error.status;

    if(status === 400){
      console.log(error.error);
      
      if (error.error.Message !== undefined) {
        Swal.fire({
          icon: 'error',
          text: error.error.Message,
        })
      }else{
        Swal.fire({
          icon: 'error',
          text: error.error.error_description,
        })
      }
      
      // if(error.error.error != 'invalid_grant'){
      //   this.showError(error.error.Message)
      // }else{
      //   console.log(error.error.error);
        
      //   if(error.error.error=="invalid_user"){
      //     // this.showError(error.error.error_description)
       
      //   }
      // }
    }else if(status === 401){
      // auto logout if 401 response returned from api
      this.authenticationService.logout();
      location.reload();
    }else if(status === 500){
      this.showError('Server Error')
    }else{
      this.showError('Something went wrong! Try Again')
    }
  }


  showError(error: string) {
    this.toastr.error(error, 'Error!' , {
      timeOut: 3000
    });
  }

}
