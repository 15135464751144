import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { CardsComponent } from './components/cards/cards.component';
import { TotalResultsComponent } from './components/total-results/total-results.component';


@NgModule({
  declarations: [
    PdfViewerComponent,
    CardsComponent,
    TotalResultsComponent
  ],
  imports: [
    CommonModule,

  ], exports: [
    PdfViewerComponent,
    CardsComponent,
    TotalResultsComponent
  ]
})
export class HelpersModule { }
