<div class="header">
  <div class="row">
    <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10">
      <h5 class="title">Select a Company</h5>
    </div>

    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
      <div class="btn-list">
        <mat-icon (click)="close()" class="close-button">close</mat-icon>
      </div>
    </div>
  </div>
  <hr class="line">

</div>

<form [formGroup]="companyForm">
    <mat-form-field appearance="outline" >
      <mat-label>Select a company</mat-label>
      <mat-select formControlName="companyId" (selectionChange)="selectChangeCompanyHandler($event)">
        <mat-option [value]="company" *ngFor="let company of companyList">
          {{company.Name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
</form>

<div class="modal-footer">
  <button mat-raised-button color="primary" (click)="submit()" [disabled]="!companyForm.valid">Submit</button> &nbsp; &nbsp;
</div>


