import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { CompanyList } from '../_models/companyList.model';
import { AuthService } from '../_services/auth.service';
import { SharedService } from '../_services/shared.service';

@Component({
  selector: 'app-company-select-dialog',
  templateUrl: './company-select-dialog.component.html',
  styleUrls: ['./company-select-dialog.component.scss']
})
export class CompanySelectDialogComponent implements OnInit {

  companyForm: FormGroup;
  userId: string;
  companyList: CompanyList[];

  constructor(
    private dialogRef: MatDialogRef<CompanySelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private sharedService: SharedService
  ) { }


  ngOnInit(): void {
    this.createForm();
    this.getCompanyList();
  }


  createForm(){
    this.companyForm = this.fb.group({
      companyId: ['', Validators.required]
    })
  }


  getCompanyList(){
    this.userId = JSON.parse(localStorage.getItem('userId'));

    this.authService.getCompanyList(this.userId)
      .pipe()
      .subscribe((companyList) => {
        this.companyList = companyList;
      }, err => {
        console.log(err)
      })
  }


  selectChangeCompanyHandler(event: any) {
    // console.log(event.value)
    localStorage.setItem('companyId', JSON.stringify(event.value.CompanyId));
    localStorage.setItem('companyName', JSON.stringify(event.value.Name));
    localStorage.setItem('Phone', JSON.stringify(event.value.Phone));
    localStorage.setItem('SortCode', JSON.stringify(event.value.SortCode));
    localStorage.setItem('IsEditOperationDetailsAllowed', JSON.stringify(event.value.IsEditOperationDetailsAllowed))
  }


  submit(){
    this.sharedService.setCompanyDetails();
    this.sharedService.setDashboardDetails();

    this.dialogRef.close();
    
    this.router.navigate(['/home/dashboard']);

    setTimeout(() => {
      window.location.reload();
    }, 2000);

  }


  close(){
    this.dialogRef.close();
  }


}
