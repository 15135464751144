import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private subjectCompanyDetails = new Subject<any>();
  private subjectDashboardDetails = new Subject<any>();

  constructor() { }

  setCompanyDetails(){
    this.subjectCompanyDetails.next();
  }


  getCompanyDetails(): Observable<any>{
    return this.subjectCompanyDetails.asObservable();
  }


  setDashboardDetails(){
    this.subjectDashboardDetails.next();
  }


  getDashboardDetails(){
    return this.subjectDashboardDetails.asObservable();
  }

}
