<div class="plain-card">
  <div class="card-statistic-4">
    <div class="align-items-center justify-content-between">
      <div class="row ">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 card-spacing">
          <div class="card-content">
            <h5 class="font-15">{{cardLabel}}</h5>
            <h2 class="mb-3 font-18">{{valueCount}}</h2>
            <!-- <p class="mb-0"><span class="col-green">10%</span> Increase</p> -->
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
          <div class="banner-img">
            <img src="../../../../assets/images/vectors/{{cardImg}}" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
