import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {
  @Input() count: any;
  @Input() label: any;
  @Input() img: any;
  valueCount: number;
  cardLabel: string;
  cardImg: string;

  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges() {
    this.cardLabel = this.label;
    this.valueCount = this.count;
    this.cardImg = this.img;


  }
}
