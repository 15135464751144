import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { ROUTES } from './sidebar-items';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { SharedService } from 'src/app/authentication/_services/shared.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subscription } from 'rxjs';
import { GlobalService } from 'src/app/helpers/services/global.service';
import { CONSTANTS } from 'src/app/helpers/services/constants';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  public sidebarItems: any[];
  level1Menu = '';
  level2Menu = '';
  level3Menu = '';
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userImg: string;
  userType: string;
  headerHeight = 60;
  currentRoute: string;
  routerObj: any = null;
  companyName: string;
  setCompanySubscription: Subscription;

  excludeArray: any = CONSTANTS.EXCLUDE_ROUTES;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    private sharedService: SharedService,
    private globalService: GlobalService
  ) {
    const body = this.elementRef.nativeElement.closest('body');

    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // logic for select active menu in dropdown
        const role = ['admin', 'teacher', 'student'];
        const currenturl = event.url.split('?')[0];
        const firstString = currenturl.split('/').slice(1)[0];

        if (role.indexOf(firstString) !== -1) {
          this.level1Menu = currenturl.split('/')[2];
          this.level2Menu = currenturl.split('/')[3];
        } else {
          this.level1Menu = currenturl.split('/')[1];
          this.level2Menu = currenturl.split('/')[2];
        }
        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
      }
    });

    this.setCompanySubscription = this.sharedService.getCompanyDetails()
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.getCompanyDetails();
      });
  }

  @HostListener('window:resize', ['$event'])

  windowResizecall(event: any) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }


  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }


  callLevel1Toggle(event: any, element: any) {
    if (element === this.level1Menu) {
      this.level1Menu = '0';
    } else {
      this.level1Menu = element;
    }
    const hasClass = event.target.classList.contains('toggled');
    if (hasClass) {
      this.renderer.removeClass(event.target, 'toggled');
    } else {
      this.renderer.addClass(event.target, 'toggled');
    }
  }


  callLevel2Toggle(event: any, element: any) {
    if (element === this.level2Menu) {
      this.level2Menu = '0';
    } else {
      this.level2Menu = element;
    }
  }


  callLevel3Toggle(event: any, element: any) {
    if (element === this.level3Menu) {
      this.level3Menu = '0';
    } else {
      this.level3Menu = element;
    }
  }


  ngOnInit() {
    if (this.authService.currentUserValue) {
      const userRole = 'Admin';

      const companyDetId: string = this.globalService.getCompanyId().toString();

      this.sidebarItems = ROUTES.filter(
        (x) => x.role.indexOf(userRole) !== -1 || x.role.indexOf('All') !== -1
      );

      if (companyDetId == CONSTANTS.TMDONE_COMPANY) {
        const route_arr: any = ROUTES;
        for (let i = 0; i < ROUTES.length; i++) {
          const element = ROUTES[i];
          if (element.submenu.length > 0) {
            for (let j = 0; j < element.submenu.length; j++) {
              const el2 = element.submenu[j];
              if (this.excludeArray.includes(el2.path)) {
                 route_arr[i].submenu.splice(j,1);
              }
            }
          }
        }

        this.sidebarItems = route_arr;

      }

    }

    this.initLeftSidebar();
    this.bodyTag = this.document.body;
    this.getCompanyDetails();

  }


  getCompanyDetails() {
    this.companyName = JSON.parse(localStorage.getItem('companyName'));
  }


  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }


  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }


  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }


  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }


  checkStatuForResize(firstTime: any) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }


  mouseHover(e: any) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  }


  mouseOut(e: any) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }


  logout() {
    this.authService.logout();
  }
}
